
<template>
  <BackofficeBase :loader_prop="loader_prop">

    <DistributionPointMenu :point_id_prod = "point_id_prod" />

    <p>{{view_data.name}}</p>
    <router-link :to="{path : '/backoffice/edit_units', query : {'id' : 0 ,'distribution': point_id_prod}}"> הוספת יחידה </router-link><br><br>
    <div v-for = "units in view_data" :key = "units.units_id">
      <p class = "unis_title">{{units.unit_name}} </p>
      <p v-if ="units.battery_status != null" >{{units.battery_status}}% :מצב סוללה</p>
      <p v-if = "units.battery_status == null"> מצב סוללה: <spain style = "color: red "> אין נתונים</spain></p> 
      <p v-if = "units.gsm != null">{{units.gsm}} :gsm סטטוס </p>
      <p v-if = "units.gsm == null"> <spain style = "color: red"> אין נתונים </spain>:gsm סטטוס </p>

      <router-link :to="{path : '/backoffice/edit_units', query : {'id' : units.units_id }}">עריכה</router-link> |
      <router-link :to="{path : '/backoffice/edit_boxes', query : {'id' : units.units_id }}">ניהול תיבות</router-link> |
      <router-link :to="{path : '/backoffice/unit_delete_alert', query : {'id' : units.units_id}}">מחיקה </router-link> 
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";
import DistributionPointMenu from "./DistributionPointMenu"

export default {
  name: 'BackofficePointManagement',
  components: {
    BackofficeBase,
    DistributionPointMenu
  },
  data() {
    return {
      loader_prop : true,
      view_data : [],
      point_id_prod : 0
    }

  },
  async mounted() {
    this.id = this.$route.query.id
    this.point_id_prod = this.$route.query.id
    let backendModel = new BackendModel()
    let view_data = await backendModel.backendRequest("/Api/service/backoffice/get_distribution_point", {"id": this.id})
    this.view_data = view_data.data.distribution_point
    this.loader_prop = false

  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>

